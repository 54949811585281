<template>
    <div>
        <modal :name="modal_name" :class="{'modalZindex':safariTrue,'modal-fix-width':loggedInUser.role == 'customer'}" class="item-summary-component increase-width-popups popup-modal-all-width-height-height-500" transition="nice-modal-fade">
            <div class="v-modal-content">
                <div class="v-modal-header" style="background: #005dae !important;">
                    <span class="v-modal-dialog-title v-modal-title" style="color: #fff !important;">ITEMS SUMMARY</span>
                    <span @click="hideItemSummary()">
                        <i class="icon icon-close pointer" style="color: #fff !important;"></i>
                    </span>
                </div>
                <div class="general-section" style="padding: 15px 0px;">
                <div class="newBox m-5">
                    <div class="v-modal-body pr-0 pt-0 pl-0">
                        <div class="">
                            <div class="">
                                <table class="table">
                                    <thead>
                                        <tr class="header-background w-100 mt-2">
                                            <th>Products</th>
                                            <th>Quantity</th>
                                            <th>Rate</th>
                                            <th>Total Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody class="item-summary-body">
                                        <template v-for="(course,cindex) in order.courses">
                                            <tr :key="cindex" style="height: 40px !important;">
                                                <td colspan="4" class="font-weight-bold">Course {{course.course}}</td>
                                            </tr>
                                            <template v-for="item in course.items">
                                                <tr :key="item._id" style="height:40px;">
                                                    <td class="font-600 col-4" v-if="item.variant_id" style="width:20%">
                                                        <el-tooltip v-if="item.variant_type == 'Non-Veg'" content="Non-Veg"
                                                            placement="top">
                                                            <img
                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg" />
                                                        </el-tooltip>
                                                        <el-tooltip v-else-if="item.variant_type == 'Veg'" content="Veg"
                                                            placement="top">
                                                            <img
                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg" />
                                                        </el-tooltip>
                                                        <el-tooltip v-else-if="item.variant_type == 'Egg'" content="Egg"
                                                            placement="top">
                                                            <img
                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg" />
                                                        </el-tooltip>
                                                        <el-tooltip v-else-if="item.variant_type == 'Pescatarian'"
                                                            content="Pescatarian" placement="top">
                                                            <img style="width:15px;"
                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/psec.svg" />
                                                        </el-tooltip>
                                                        <span class="mt-1 text-secondary font-weight-bold"
                                                            :class="{'ml-1':item.variant_type != ''}">{{item.product_name}}</span>
                                                    </td>
                                                    <td class="font-600 col-4" style="width:20%" v-else>
                                                        <el-tooltip v-if="item.product_type == 'Non-Veg'" content="Non-Veg"
                                                            placement="top">
                                                            <img
                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/non-veg.svg" />
                                                        </el-tooltip>
                                                        <el-tooltip v-else-if="item.product_type == 'Veg'" content="Veg"
                                                            placement="top">
                                                            <img
                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/veg.svg" />
                                                        </el-tooltip>
                                                        <el-tooltip v-else-if="item.product_type == 'Egg'" content="Egg"
                                                            placement="top">
                                                            <img
                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/egg.svg" />
                                                        </el-tooltip>
                                                        <el-tooltip v-else-if="item.product_type == 'Pescatarian'"
                                                            content="Pescatarian" placement="top">
                                                            <img style="width:15px;"
                                                                src="https://cdn.digicollect.com/cdn/pos/images-new-theme/psec.svg" />
                                                        </el-tooltip>
                                                        <span class="mt-1 text-secondary font-weight-bold"
                                                            :class="{'ml-1':item.product_type != ''}">{{item.product_name}}</span>
                                                    </td>
                                                    <td class="font-600 col-2" style="width:20%">{{item.quantity}}</td>
                                                    <td class="font-600 col-3" style="width:20%">{{item.product_price}}</td>
                                                    <td class="font-600 col-3" style="width:20%">{{item.product_price_exclusive}}</td>
                                                </tr>

                                                <tr :key="item._id+'idd9'">
                                                    <td colspan="4">
                                                        <div class="text-blue font-600 fs-14 py-1">
                                                            <span>{{item.variant_name || ""}}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr v-if="item.modifiers" :key="item._id+'idd9'">
                                                    <td colspan="4" style="padding: 0 !important;border-radius: 6px; width: 100%;">
                                                        <table class="w-100">
                                                            <tbody>
                                                                <template v-if="item.modifiers.hasOwnProperty('for_secondary')">
                                                                    <tr v-for="(modifier, modifier_index) in item.modifiers.for_secondary" :key="modifier_index">
                                                                        <td class="font-600 col-4" style="width:20%">
                                                                            <div class="d-flex ml-0 pl-0">
                                                                                <span class="font-weight-bold"
                                                                                        >{{modifier.m_item_name}}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td class="font-600 col-2" style="width:20%;vertical-align:bottom">
                                                                            <div v-if="modifier.has_price">
                                                                                {{modifier.quantity}}
                                                                            </div>
                                                                        </td>
                                                                        <td class="font-600 col-3" style="width:20%;vertical-align:bottom">
                                                                            <div v-if="modifier.has_price">
                                                                                {{modifier.price}}
                                                                            </div>
                                                                        </td>
                                                                        <td class="font-600 col-3" style="width:20%;vertical-align:bottom">
                                                                            <div v-if="modifier.has_price">
                                                                                {{modifier.amount}}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </template>
                                                            </tbody>
                                                            <template v-if="item.modifiers.hasOwnProperty('for_primary')">
                                                                <tbody v-for="(modifier, modifier_index) in item.modifiers.for_primary" :key="modifier_index + 'modtem1'">
                                                                    <tr>
                                                                        <td colspan="4" style="border-radius: 0px;padding: 0.4rem 1.2rem!important;">
                                                                            <div class="text-blue font-600 fs-14">
                                                                                <span>{{modifier.modifier_name}}</span>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                    <tr v-for="(modifier_item, modifier_item_index) in modifier.modifier_items" :key="modifier_item_index">
                                                                        <td class="font-600 col-4" style="width:20%;">
                                                                            <div class="d-flex ml-0 pl-0">
                                                                                <span class="font-weight-bold">{{modifier_item.m_item_name}}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td class="font-600 col-2" style="width:20%;vertical-align:bottom">
                                                                            <div v-if="modifier.has_price">
                                                                                {{modifier_item.quantity}}
                                                                            </div>
                                                                        </td>
                                                                        <td class="font-600 col-3" style="width:20%;vertical-align:bottom">
                                                                            <div v-if="modifier.has_price">
                                                                                {{modifier_item.price}}
                                                                            </div>
                                                                        </td>
                                                                        <td class="font-600 col-3" style="width:20%;vertical-align:bottom">
                                                                            <div v-if="modifier.has_price">
                                                                                {{modifier_item.amount}}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </template>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </template>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                            <div class="d-flex justify-content-between bg-orange py-3 ">
                                <span class="font-weight-bold fs-16 col-9">Grand Total</span>
                                <span class="font-weight-bold fs-16 col-3">{{order.grand_total}}</span>
                            </div>
                            <!-- <div v-if="order.upcharge_for_credit_card_usage" class="d-flex justify-content-between py-3 ">
                                <span class="font-weight-bold fs-16 col-9" style="color: #4e5764;">Payment Type:
                                    <span style="font-weight: 500; color: #4e5764;">Credit Card</span>
                                </span>
                                <span class="fs-16 col-3">
                                    <el-switch class="ml-2 approvalswitch" @change="isCreditCardUsed($event)" v-model="order.is_credit_card_used"></el-switch>
                                </span>
                            </div> -->
                            <div v-if="order.is_credit_card_used" class="d-flex justify-content-between py-3 ">
                                <span class="font-weight-bold fs-16 col-9">Credit Card Charges</span>
                                <span class="font-weight-bold fs-16 col-3">{{order.credit_card_charges_amount}}</span>
                            </div>
                            <div v-if="order.is_credit_card_used" class="d-flex justify-content-between py-3 ">
                                <span class="fw-normal fs-16 col-9">Round Off</span>
                                <span class="fw-normal fs-16 col-3">({{order.round_off.amount}})</span>
                            </div>
                            <div v-if="order.is_credit_card_used" class="d-flex justify-content-between bg-orange py-3 ">
                                <span class="font-weight-bold fs-16 col-9" style="color: #000;">Grand Total with CC Charges</span>
                                <span class="font-weight-bold fs-16 col-3">{{order.grand_total_with_credit_card_charges}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div class="v-modal-dialog-actions modal-bg text-center pt-2 d-block">
                    <div class="d-flex mt-3">
                        <button v-if="!editOrder && loggedInUser.role != 'customer' && !order.hold_order_before_placing" @click="fastpay()"
                            class="pointer d-flex justify-content-between align-items-center bg-green-image pl-2 pr-2 pt-1 pb-1 b-r-7 w-100">
                            <span class="text-white font-weight-bold d-flex align-items-center fs-16">Fast Pay</span>
                            <span>
                                <img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/fast-pay.svg" />
                            </span>
                        </button>
                        <button v-if="loggedInUser.role != 'customer' && loggedInUser.enable_fast_checkout" @click="fastCheckout()" :class="{'ml-3':!editOrder}"
                            class="pointer d-flex justify-content-between bg-blue-image align-items-center pl-2 pr-2 pt-1 pb-1 b-r-7 w-100">
                            <span class="fs-16 text-white font-weight-bold d-flex align-items-center">
                                Fast
                                Checkout
                            </span>
                            <span>
                                <img src="https://cdn.digicollect.com/cdn/posv2/fast-checkout.svg" />
                            </span>
                        </button>
                        <button v-if="editOrder" @click="checkoutItem()" class="pointer d-flex justify-content-between bg-orange-image align-items-center pl-2 pr-2 pt-1 pb-1 ml-3 b-r-7 float-right w-100" :class="{'w-50':loggedInUser.role != 'customer','w-100':loggedInUser.role == 'customer'}">
                            <span v-if="loggedInUser.role == 'customer'" class="font-weight-bold fs-16">Place Order</span>
                            <span v-else class="font-weight-bold fs-16">Checkout</span>
                            <span><img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/checkout.svg" /></span>
                        </button>
                    </div>
                    <button v-if="!editOrder" @click="checkoutItem()" class="pointer w-100 float-right d-flex justify-content-between align-items-center bg-blue-image p-3 bg-orange-image mt-3 b-r-7" :class="{'w-50':loggedInUser.role != 'customer','w-100':loggedInUser.role == 'customer'}">
                        <span v-if="loggedInUser.role == 'customer'" class="font-weight-bold fs-16">Place Order</span>
                        <span v-else class="font-weight-bold fs-16">Checkout</span>
                        <span><img src="https://cdn.digicollect.com/cdn/pos/images-new-theme/checkout.svg"/></span>
                    </button>
                </div>
            </div>
        </modal>
        <payment-method modal_name="method_payment_item" :summary="summary" :is_credit_card_used="order.is_credit_card_used" :grand_total_value="grand_total_value"
            :grand_total_amount="grand_total_amount" :fastPay="true" v-if="showpay" @close="closePaymentMethod()">
        </payment-method>
        <sweet-modal ref="success_modal" icon="success">{{ modal_msg }}</sweet-modal>
        <sweet-modal ref="warning_modal" icon="warning">{{ modal_msg }}</sweet-modal>
    </div>
</template>
<script>
    import {
        SweetModal
    } from 'sweet-modal-vue'
    import orders from './mixins/orders'
    const PaymentMethod = () => import('./PaymentMethod')
    export default {
        props: ['modal_name', 'order', 'editOrder','order_id'],
        mixins: [orders],
        data() {
            return {
                showAddons: [],
                showModifiers: [],
                summary: '',
                grand_total_value: '',
                grand_total_amount: '',
                modal_msg: '',
                showpay: false,
                safariTrue: false,
                isActive: false,
                isTablet: false
            }
        },
        components: {
            PaymentMethod,
            SweetModal
        },
        methods: {
            async isCreditCardUsed(value) {
                try {
                    let params = {
                        order_id: this.order_id,
                        is_credit_card_used: this.order.is_credit_card_used
                    };
                    let response = await this.creditCardUsageActive(params);
                    this.modal_msg = response.message;
                    this.$refs.success_modal.open();
                    setTimeout(() => {
                        this.$refs.success_modal.close();
                        this.$emit("updateItemSummary");
                    }, 2000);
                }
                catch (error) {
                    this.modal_msg = error;
                    this.$refs.warning_modal.open();
                    setTimeout(() => this.$refs.warning_modal.close(), 2000);
                }
            },
            hideItemSummary() {
                this.$emit('hideItemsSummary')
                this.$modal.hide(this.modal_name)
            },
            checkoutItem() {
                this.$emit('checkoutItems')
                this.$modal.hide(this.modal_name)
            },
            expandAddons(item, item_index) {
                if (this.showAddons.find(x => x === item._id)) {
                    this.showAddons = this.showAddons.filter(function (x) {
                        if (x !== item._id) {
                            return x
                        }
                    })
                } else {
                    this.showAddons.push(item._id)
                }
            },
            expandModifiers(item, item_index) {
                if (this.showModifiers.find(x => x === item._id)) {
                    this.showModifiers = this.showModifiers.filter(function (x) {
                        if (x !== item._id) {
                            return x
                        }
                    })
                } else {
                    this.showModifiers.push(item._id)
                }
            },
            fastCheckout() {
                this.$emit('fastCheckoutAddons')
                this.$modal.hide(this.modal_name)
            },
            async fastpay() {
                try {
                    this.$http.get('/cashier/is_cashier_session_active').then(res => {
                        this.modal_msg = res.data.reason
                        if (res.data.status_id == 1) {
                            this.$store.commit(
                                'setCashRegister',
                                res.data.response.cash_register_details
                            )
                            this.fastPayConfirm()
                        } else {
                            this.$toasted.global.error(res.data.reason)
                        }
                    })
                } catch (reason) {
                    this.$toasted.global.error(reason)
                }
            },
            fastPayConfirm() {
                this.showpay = true
                this.$modal.hide(this.modal_name)
                this.summary = this.order
                this.$http
                    .post('orders/grand_total', {
                        order_id: this.order._id
                    })
                    .then(response => {
                        this.grand_total_value = response.data.value.toFixed(2)
                        this.grand_total_amount = response.data.amount
                        this.$modal.show('method_payment_item')
                    })
            },
            closePaymentMethod() {
                this.showpay = false
                this.$modal.show(this.modal_name)
                this.$modal.hide('method_payment_item')
            }
        },
        created() {

        },
        mounted() {
            this.safariTrue = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
            if(this.loggedInUser.role == 'customer'){
                let items = this.order.courses.map(x => {return x.items})
                this.order['items'] = items
                this.order.items.forEach((item, item_index) => {
                    this.showAddons.push(item._id)
                    this.showModifiers.push(item._id)
                })
            }
            else{
                this.order.items.forEach((item, item_index) => {
                    this.showAddons.push(item._id)
                    this.showModifiers.push(item._id)
                })
            }
        },
        computed: {
            loggedInUser() {
                return this.$store.state.loggedInUser
            }
        }
    }

</script>
<style scoped>
    .customize:nth-of-type(odd) {
        background: #e0e0e0;
    }

    .item-summary-component .table td {
        padding: 0.6rem 1.2rem !important;
    }

    .add-custom-addon {
        width: 75px !important;
        border-bottom: none !important;
        color: #00448b !important;
    }

    .v-modal-layout {
        overflow: initial !important;
    }

    .bg-green-image {
        background-image: linear-gradient(83deg,
                #1a9347 8%,
                #1ca04d 53%,
                #20b256 93%);
        outline: 0;
    }

    .bg-blue-image {
        outline: 0;
        background: #005dae;
    }

    .bg-orange-image {
        outline: 0;
        background-image: linear-gradient(315deg, #fccb4a -30%, #f0712a 48%);
    }

    .b-r-7 {
        border-radius: 7px;
    }

    .table thead th {
        color: #fff !important;
        font-size: 14px !important;
        text-transform: capitalize !important;
        padding: 8px 10px !important
    }

    tbody:nth-of-type(odd) {
        background-color: #fff !important;
    }

    .addon-list {
        border: solid 1px #a2a2a2;
        border-radius: 7px;
        padding: 6px;
        background: #f5f6f9;
    }

    .item-collapse img {
        position: relative;
        right: 17px;
        display: block;
        float: right;
        bottom: 12px;
        height: 26px !important;
        width: 26px;
    }

    .item-rotate {
        transform: rotate(180deg);
    }

    .addon-list div {
        /* max-height: 0; */
        -webkit-transition: max-height 0.5s ease-in-out;
        -moz-transition: max-height 0.5s ease-in-out;
        -o-transition: max-height 0.5s ease-in-out;
        transition: max-height 0.5s ease-in-out;
    }

    .word-break {
        word-break: break-word;
    }
    .newBox {
        box-shadow: 0px 5.5px 17px 0px rgba(0, 0, 0, 0.16);
        background: #fff;
        padding: 10px 0px;
        border-radius: 8px;
        margin-top: 5px;
        margin-bottom: 5px;
        border: 1px solid #00448b;
    }
    .bg-orange {
        background-color : #fccb4a;
    }
    .header-background {
        background: #00448b;
    }
    .input-circle {
        border-radius: 50%;
        background: #00448b;
        color: #000;
        text-align: center;
        font-size: 16px;
        float: right;
    }
    .item-position {
        right: 10px;
        bottom: 10px;
    }
    .icon-style {
        height: 23px !important;
        margin-bottom: 3px;
    }
    tbody tr{
        height: 30px !important;
    }
</style>
